@import '~bootstrap/scss/bootstrap';
@import '~trumbowyg/dist/ui/sass/trumbowyg';

.modal-backdrop.show { opacity: 0.75; }

.btn-primary {
  background-color: #336c7d;
  color: #fff;
  border: 0 !important;
  &:hover,
  &:active,
  &:focus {
    background-color: #40869c !important;
    border: 0 !important;
    outline: 0 !important;
  }
  &:disabled {
    color: #999;
    background-color: rgba(0, 123, 255, 0.25);
  }
}

.badge-primary {
  background-color: #336c7d;
  color: #fff !important;
  font-weight: normal;
  &:hover,
  &:active,
  &:focus {
    background-color: #40869c !important;
    border: 0 !important;
    outline: 0 !important;
  }
}


ul.color-list {
  margin-top: 20px;
  list-style-type: none;

  li {
    margin-bottom: 15px;
    span {
      display: inline-block;
      width: 200px;
      font-size: 16px;
      line-height: 16px;
    }
    a {
      font-size: 14px;
      line-height: 16px;
      margin-right: 10px;
    }
  }

}

form.addColor {
  padding-left: 10px;
}

form {

  .form-group {
    margin: 0 0 35px 0;
  }


  label:not(.form-check-label) {
    font-weight: bold;
  }
}

.product-image {
  max-height: 170px;
}

.form-control[type="file"] {
  border: 0;
}
